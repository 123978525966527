<template>
	<el-dialog top="1vh" title="用户详情" width="1050px" :visible.sync="is_show_in_page">
		<el-form class="form_ordinary" label-width="80px" style="margin-top:-25px">

			<div class="big_tit" style="margin-top:0px;">基本数据</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="用户手机" style="width:340px">
					<el-input class="el_inner_width" v-model="tel" :disabled="tel_status==2" clearable>
						<el-button type="success" slot="append" @click="tel_check">查找</el-button>
					</el-input>
				</el-form-item>
				<el-form-item class="el_form_item" label="用户姓名" style="width:300px">
					<el-input class="el_inner_width" v-model="name" clearable></el-input>
				</el-form-item>
				<el-form-item class="el_form_item" label="身份证号" style="width:320px">
					<el-input class="el_inner_width" v-model="id_card_num" clearable></el-input>
				</el-form-item>
			</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="用户地址" style="width:662px">
					<el-input class="el_inner_width" v-model="addr" clearable></el-input>
				</el-form-item>
			</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="用户密码" style="width:320px">
					<el-input class="el_inner_width" v-model="login_pwd" clearable type="password"></el-input>
				</el-form-item>
			</div>

			<div class="big_tit">头像/身份证</div>
			<div class="tab1_inner">
				<div class="el_form_item" label="" style="width:32%">
					<div class="img_btn" @click="choose_img('headimg')">
						<div v-if="!headimg.src" class="btn">未上传</div>
						<img  v-else :src="headimg.src" class="img">
					</div>
					<div class="bottom_text">头像</div>
				</div>
				<div class="el_form_item" style="width:32%;margin-left:1%">
					<div class="img_btn" @click="choose_img('id_card_img1')">
						<div v-if="!id_card_img1.src" class="btn">未上传</div>
						<img  v-else :src="id_card_img1.src" class="img">
					</div>
					<div class="bottom_text">身份证正面</div>
				</div>
				<div class="el_form_item" style="width:32%;margin-left:1%">
					<div class="img_btn" @click="choose_img('id_card_img2')">
						<div v-if="!id_card_img2.src" class="btn">未上传</div>
						<img  v-else :src="id_card_img2.src" class="img">
					</div>
					<div class="bottom_text">身份证反面</div>
				</div>
			</div>
		</el-form>

		<div class="bottom_btns">
			<el-button type="danger" @click="page_init()">清空</el-button>
			<el-button type="primary" @click="sub()">提交</el-button>
		</div>

	</el-dialog>
</template>
<script>
	export default {
		props:{
			is_show:Number,//是否显示
		},
		data() {
			return {

				//页面是否显示
				is_show_in_page:false,
				login_pwd:'',//登录密码
				//用户编号
				user_num:'',

				//用户手机
				tel:'',
				tel_status:'1',//状态(1:未校验/不可用,2:校验可用)

				//用户姓名
				name:'',

				//身份证号
				id_card_num:'',

				//用户地址
				addr:'',

				//头像
				headimg:{
					src:'',
					key:'',
					is_upl:false,
					is_choose:false,
					bucket:'headimg'
				},

				//身份证正面
				id_card_img1:{
					src:'',
					key:'',
					is_upl:false,
					is_choose:false,
					bucket:'idcard1'
				},

				//身份证反面
				id_card_img2:{
					src:'',
					key:'',
					is_upl:false,
					is_choose:false,
					bucket:'idcard2'
				},
				
				//提交锁
				sub_swh:false
			}
		},
		watch:{
			is_show(new_data){
				if(new_data){
					this.page_init();
					this.is_show_in_page=true;
				}
			},
		},
		methods: {

			//提交
			sub(){

				if(this.tel_status==1){
					this.$my.other.msg({
						type:'warning',
						str:'请输入正确的手机号码, 然后验证'
					});
					return;
				}

				if(this.sub_swh){
					return;
				}

				//上锁
				this.sub_swh=true

				//调接口
				this.$my.net.req({
					data:{
						mod:'app_user',
						ctr:'app_user_edit_by_admin',
						is_real_check:1,//要求进行实名认证(需要同时传 姓名和身份证号)
						user_num:this.user_num,
						name:this.name,
						id_card_num:this.id_card_num,
						addr:this.addr,
						headimg:this.headimg.key,
						id_card_img1:this.id_card_img1.key,
						id_card_img2:this.id_card_img2.key,
						login_pwd:this.login_pwd
					},
					callback:(data)=>{
						
						//报告结果
						this.$my.other.msg({
							type:'success',
							str:'操作成功'
						});

						//表单初始化
						this.page_init();
					}
				});
			},

			//选择图片
			choose_img(img_name) {

				if(this.tel_status!=2){
					return;
				}

				//取出相应对象
				let ctr_obj = this[img_name]

				//选择图片
				this.$my.qiniu.choose_img({
					success: (res) => {
						ctr_obj.src = res.src;
						ctr_obj.is_choose = true;
						ctr_obj.is_upl = false;

						//上传图片
						this.$my.qiniu.upl({
							bucket: ctr_obj.bucket,
							key: ctr_obj.key,
							file_path: ctr_obj.src,
							callback: (data) => {
								ctr_obj.is_upl = true;
								ctr_obj.key = data.key;
								this.save_img(img_name);
							}
						});
					}
				});
			},
			save_img(img_name){//保存图片

				//构造表单
				let form={
					mod:'app_user',
					ctr:'app_user_edit_by_admin',
					user_num:this.user_num,
				};
				form[img_name]=this[img_name].key

				//发送
				this.$my.net.req({
					data:form,
					callback:(data)=>{

						//ocr
						if(img_name=='id_card_img1'){

							this.$my.net.req({
								data:{
									mod:'res',
									ctr:'img_ocr',
									img_type:'id_card',
									img_url:this.$my.qiniu.make_src(this[img_name].bucket,this.id_card_img1.key)
								},
								callback:(data)=>{
									this.name=data.name
									this.id_card_num=data.id_card_num
									this.addr=data.address
								}
							});
						}
					}
				});
			},

			//手机号校验
			tel_check(){

				if(this.tel_status==2){
					return;
				}

				if(!this.$my.check.is_tel(this.tel)){
					this.$my.other.msg({
						type:'warning',
						str:'手机号码格式有误'
					});
					return;
				}

				//查找用户
				this.$my.net.req({
					data:{
						mod:'app_user',
						ctr:'app_user_list_by_admin',
						tel:this.tel,
					},
					callback:(data)=>{

						//未注册
						if(data.list.length==0){
							this.tel_status=2

							//注册之
							this.$my.net.req({
								data:{
									mod:'app_user',
									ctr:'app_user_add_by_admin',
									tel:this.tel,
								},
								callback:(data)=>{
									
									//缓存用户编号
									this.user_num=data.user_num
								}
							});
							return;
						}

						//取出用户信息
						let user_info=data.list[0]

						//已注册已实名
						if(user_info.real_status==2){
							this.$my.other.msg({
								type:'warning',
								str:'手机号已使用'
							});
							return;
						}

						//已注册未实名
						this.tel_status=2
						this.user_num=user_info.user_num
						this.name=user_info.name
						this.id_card_num=user_info.id_card_num
						this.addr=user_info.addr

						//头像
						if(user_info.headimg){
							this.headimg.key=user_info.headimg
							this.headimg.src=this.$my.qiniu.make_src(this.headimg.bucket,user_info.headimg)
						}
						
						//身份证正面
						if(user_info.id_card_img1){
							this.id_card_img1.key=user_info.id_card_img1
							this.id_card_img1.src=this.$my.qiniu.make_src(this.id_card_img1.bucket,user_info.id_card_img1)
						}

						//身份证反面
						if(user_info.id_card_img2){
							this.id_card_img2.key=user_info.id_card_img2
							this.id_card_img2.src=this.$my.qiniu.make_src(this.id_card_img2.bucket,user_info.id_card_img2)
						}
					}
				});
			},

			//页面初始化
			page_init(){

				//状态
				this.tel_status=1
				
				//用户基本数据
				this.tel=''
				this.name=''
				this.id_card_num=''
				this.addr=''

				//头像
				this.headimg.key=""
				this.headimg.src=""
				this.headimg.is_choose=false
				this.headimg.is_upl=false

				//身份证正面
				this.id_card_img1.key=""
				this.id_card_img1.src=""
				this.id_card_img1.is_choose=false
				this.id_card_img1.is_upl=false

				//身份证反面
				this.id_card_img2.key=""
				this.id_card_img2.src=""
				this.id_card_img2.is_choose=false
				this.id_card_img2.is_upl=false
			},
		}
	}
</script>
<style lang="scss" scoped>

	.el_form_item{
		margin: 0;
		.img_btn{
			border: 1px solid #bbb;
			border-radius: 5px;
			cursor: pointer;
			padding: 5px;
			height:200px;
			.btn{
				text-align: center;
				line-height: 200px;
				font-size: 14px;
			}

			.img{
				display: block;
				margin:auto;
				max-width: 100%;
				max-height: 200px
			}
		}
		.bottom_text{
			margin-top: 5px;
			text-align: center;
		}
	}
	.big_tit{
		background-color: #eee;
		padding: 10px 20px;
		color: #666;
		font-weight: 600;
		font-size: 18px;
		margin-top: 20px;
	}
	.tab1_inner{
		padding: 15px 15px 0 15px;
		display: flex;
		align-items: flex-start;
		flex-wrap: wrap;
	}
	.el_inner_width{
		width:94%;
	}
	
	.bottom_btns{
		text-align: center;
		margin-top: 30px;
	}

	.form_ordinary{
		// height: calc(100% - 91px);
		padding: 10px 0;
		overflow-y:auto;
	}
	
</style>